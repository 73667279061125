import { toast } from 'react-toastify';

export const Toasts = {
  success: (message) => {
    return toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored'
    });
  },

  error: (message) => {
    return toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored'
    });
  },

  warn: (message) => {
    return toast.warn(message, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored'
    });
  },

  info: (message) => {
    return toast.info(message, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored'
    });
  }
};
