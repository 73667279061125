import { db } from "../../../../firebase";
import * as fireStore from "firebase/firestore";
import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export const fetchAllUsers = ({ page, docLimit }) =>
  new Promise(async (resolve, reject) => {
    if (page === 0) {
      fireStore.getDocs(query(collection(db, "users"), orderBy("name", "asc"), limit(docLimit)))
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
        console.log(e)
      });
      return
    }
    const first = query(collection(db, "users"), orderBy("name", "asc"), limit((page * docLimit) + 1));
const documentSnapshots = await getDocs(first);

// Get the last visible document
const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    fireStore.getDocs(query(fireStore.collection(db, "users"),orderBy("name", "asc"), startAfter(lastVisible) , limit(docLimit)))
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
      });
  });

export const onMarkDeleteUser = ({ userId, isBlocked }) =>
  new Promise(async (resolve, reject) => {
    fireStore.updateDoc(fireStore.doc(db, "users", userId), { isBlocked }).then(resolve).catch(reject)
  });

export const onUpdateUser = ({ userId, data }) =>
  new Promise(async (resolve, reject) => {
    fireStore.updateDoc(fireStore.doc(db, "users", userId), data).then(resolve).catch(reject)
  });

  export const getCountOfUserTables = async () => {
    const list = query(collection(db, "users"));
    const snapshot = await getDocs(list);
    const count = snapshot.size;
    return count
  }

  export const getCountOfPostTables = async () => {
    const list = query(collection(db, "posts"));
    const snapshot = await getDocs(list);
    const count = snapshot.size;
    return count
  }

  export const useGetCountOfTable = (type = 'users') => {
    const [count, setCount] = useState('')

    useEffect(() => {
      (async () => {
        const list = type === 'users' ? await getCountOfUserTables() : await getCountOfPostTables()
        setCount(list)
      })()
    }, [])

    return { count }
  }