import React, { useEffect, useState } from 'react'
import { TableComponent } from '../../Components/Table/Table'
import { Toasts } from '../../Components/Toasts/Toasts'
import { DashboardContainer } from '../../Containers/DashboardLayout'
import { useGetCountOfTable } from '../Home/saga/saga/saga'
import { fetchAllPosts, fetchAllUsers, onMarkDeletePosts, onMarkDeleteUser } from './saga/saga/saga'


const headings = [
  'image',
  'name',
  'description',
  'hash tags',
  'country',
  'condition',
  'discount',
  'price',
  'published on',
  'status',
]

export const Posts = () => {
  const [page, setPage] = useState(0)
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { count } = useGetCountOfTable('posts')

  const _fetchPosts = () => {
    setIsLoading(true)
    const data = []
    fetchAllPosts({ page, docLimit: 10 }).then(res => {
      if (res.size === 0) {
        Toasts.info('No more data left to render')
        return
      }
      res.forEach((item) => {
        data.push({ ...item.data(), id: item.id })
      })
    }).then(() => {
      setIsLoading(false)
      setList([...list, ...data])
    }).catch(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    _fetchPosts()
  }, [page])

  const onLoadMore = () => setPage(page + 1)
  const onDeletePost = (postId, isBlocked) => { 
    onMarkDeletePosts({ isBlocked, postId })
    .then(res => {
      const allList = [...list]
      setList(allList.map(item => ({...item, status: item?.id === postId ? item?.status === 'APPROVED' ? 'REJECTED' : 'APPROVED' : item?.status})))
    Toasts.success(isBlocked ? 'Post Rejected successfully' : 'Post Approved successfully')
    })
    .catch(res => {
      Toasts.error(res?.message)
    })
  }
  
  return (
    <DashboardContainer>
        <div className='p-4'>
          <h3 className='text-[22px] font-bold'>Posts {count > 0 && ` - ${count}`}</h3>

          <div className='mt-4 w-full'>
            <TableComponent isPosts onDelete={onDeletePost} onLoadMore={onLoadMore} isLoading={isLoading} headings={headings} rows={list} />
        </div>
        </div>
    </DashboardContainer>
  )
}
