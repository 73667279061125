import React, { useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { Home } from './Pages/Home/Home'
import { Login } from './Pages/Login/Login'
import { Posts } from './Pages/Posts/Posts'
import { getUser } from './utils/hooks'

export const Routes = () => {
  const user = getUser()
  return (
    <BrowserRouter>
        <Switch>
        <Route exact path={"/posts"} component={() => <PrivateRoute>
              <Posts />
          </PrivateRoute>} />  
        <Route exact path={"/"} component={() => <PrivateRoute>
              <Home />
          </PrivateRoute>} />        
          {(!user?.id) && (
            <>         
              <Route
                exact
                path={"/signin"}
                component={Login}
              />
            </>
          )}
          <Redirect to={'/'} />
      </Switch>
      </BrowserRouter>
  )
}

const PrivateRoute = (props) => {
  const history = useHistory()
  const user = getUser()

  useEffect(() => {
    if (!user?.id) {
      history.push(`/signin`)
    }
  }, [])

  if (user?.id) {
    return props.children
  }

  return null
}