import React, { useEffect, useState } from 'react'
import { TableComponent } from '../../Components/Table/Table'
import { Toasts } from '../../Components/Toasts/Toasts'
import { DashboardContainer } from '../../Containers/DashboardLayout'
import { fetchAllUsers, onMarkDeleteUser, onUpdateUser, useGetCountOfTable } from './saga/saga/saga'


const headings = [
  'name',
  'email',
  'phone',
  'country',
  'action'
]

export const Home = () => {
  const [page, setPage] = useState(0)
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { count } = useGetCountOfTable()

  const _fetchUsers = () => {
    setIsLoading(true)
    const data = []
    fetchAllUsers({ page, docLimit: 10 }).then(res => {
      if (res.size === 0) {
        Toasts.info('No more data left to render')
        return
      }
      res.forEach((item) => {
        data.push({ ...item.data(), id: item.id })
      })
    }).then(() => {
      setIsLoading(false)
      setList([...list, ...data])
    }).catch(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    _fetchUsers()
  }, [page])

  const onLoadMore = () => setPage(page + 1)

  const onBlockUser = (id, isBlocked) => { 
    onMarkDeleteUser({ isBlocked, userId: id })
    .then(res => {
      const allList = [...list]
      setList(allList.map(item => ({...item, isBlocked: item?.id === id ? true: item?.isBlocked})))
    Toasts.success('User Blocked successfully')
    })
    .catch(res => {
      Toasts.error(res?.message)
    })
  }

  const onUnblockUser = (id) => {
    onUpdateUser({ userId: id, data: { isBlocked: false, isDeactivated: false } }).then(res => {
      const allList = [...list]
      setList(allList.map(item => ({...item, isBlocked: item?.id === id ? false : item?.isBlocked, isDeactivated: item?.id === id ? false : item?.isDeactivated})))
    Toasts.success('User unblocked successfully')
    })
    .catch(res => {
      Toasts.error(res?.message)
    })
  }

  const onDeactivateUser = (id) => {
    onUpdateUser({ userId: id, data: { isDeactivated: true } }).then(res => {
      const allList = [...list]
      setList(allList.map(item => ({...item, isDeactivated: item?.id === id ? true : item?.isDeactivated})))
    Toasts.success('User deactivated successfully')
    })
    .catch(res => {
      Toasts.error(res?.message)
    })
  }
  
  return (
    <DashboardContainer>
        <div className='p-4'>
          <h3 className='text-[22px] font-bold'>Users {count > 0 && ` - ${count}`}</h3>

          <div className='mt-4 w-full max-w-[1000px]'>
            <TableComponent onDeactivateUser={onDeactivateUser} onUnblockUser={onUnblockUser} onDelete={onBlockUser} onLoadMore={onLoadMore} isLoading={isLoading} headings={headings} rows={list?.filter(item => !item?.isAdmin)} />
        </div>
        </div>
    </DashboardContainer>
  )
}
