import {
    signInWithEmailAndPassword,
    getAuth
  } from "firebase/auth";
import * as fireStore from "firebase/firestore";
import { Toasts } from "../../../Components/Toasts/Toasts";
import { db } from "../../../firebase";

export const loginUser = ({ email, pass }) => {
    signInWithEmailAndPassword(getAuth(), email, pass)
      .then((res) => {
        if (res.user.uid) {
            fireStore
      .getDoc(fireStore.doc(db, "users", res?.user?.uid))
      .then((res) => {
        if (res.data()?.isAdmin) {
            window.localStorage.setItem('user', JSON.stringify({...res.data(), id: res.id}))
            window.location.reload()
            return
        }
        Toasts.error("Only admins are allowed to use this portal")
      })
      .catch(
        (e) => {
            Toasts.error(e.message)
        }
      );
        }
      })
      .catch((e) => {
        Toasts.error(e.message)
      });
  };