import { db } from "../../../../firebase";
import * as fireStore from "firebase/firestore";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";

export const fetchAllPosts = ({ page, docLimit }) =>
  new Promise(async (resolve, reject) => {
    if (page === 0) {
      fireStore.getDocs(query(fireStore.collection(db, "posts"), orderBy("createdAt", "desc"), limit(docLimit)))
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
        console.log(e)
      });
      return
    }
    const first = query(
      collection(db, "posts"),
      orderBy("createdAt", "desc"),
      limit(page * docLimit)
    );
    const documentSnapshots = await getDocs(first);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    fireStore
      .getDocs(
        query(
          fireStore.collection(db, "posts"),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(docLimit)
        )
      )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
      });
  });

export const onMarkDeletePosts = ({ postId, isBlocked }) =>
  new Promise(async (resolve, reject) => {
    fireStore
      .updateDoc(fireStore.doc(db, "posts", postId), {
        status: isBlocked ? "REJECTED" : "APPROVED",
      })
      .then(resolve)
      .catch(reject);
  });
