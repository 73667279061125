import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { countries } from "../../utils/countries";
import { getFlagEmoji } from "../../utils/constants";
import { red } from "@material-ui/core/colors";
import { green } from "@mui/material/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const RejectButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[800]),
}));

const ApproveButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(green[800]),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableComponent = ({
  headings = [],
  rows = [],
  isPosts = false,
  isLoading = false,
  onLoadMore = () => null,
  onDelete = () => null,
  onUnblockUser = () => null,
  onDeactivateUser = () => null,
}) => {
  const onPerformActionUser = (type, row) => {
    if (type === "unblock") {
      onUnblockUser(row?.id);
      return;
    }
    if (type === "block") {
      onDelete(row?.id, true);
      return;
    }
    if (type === "deactivate") {
      onDeactivateUser(row?.id, true);
      return;
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headings?.map((item, i) => (
                <StyledTableCell
                  key={i}
                  align={
                    i === 0
                      ? "left"
                      : item === "description"
                      ? "center"
                      : "right"
                  }
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {isPosts ? (
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    className="min-w-[150px] max-w-[200px]"
                    component="th"
                    scope="row"
                  >
                    <div className="flex flex-row flex-wrap gap-2">
                      {row?.images?.length > 0 &&
                        row?.images?.map((item, i) => (
                          <img
                            key={i}
                            src={item}
                            className="w-[50px] h-[50px]"
                            alt={"aria"}
                          />
                        ))}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    className="min-w-[150px]"
                    component="th"
                    scope="row"
                    align="right"
                  >
                    {row.title}
                    <p>{row.id}</p>
                  </StyledTableCell>
                  <StyledTableCell className="max-w-[400px] min-w-[400px]">
                    <p className="text-center">{row.description}</p>
                  </StyledTableCell>
                  <StyledTableCell className="max-w-[100px]]" align="right">
                    {row.hashTags?.join(", ")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div className="flex flex-row text-right justify-end">
                      {countries.find(
                        (item) =>
                          item.code?.toLowerCase() ===
                          row.country?.toLowerCase()
                      )?.label || ""}
                      &nbsp; &nbsp;
                      {getFlagEmoji(
                        countries.find(
                          (item) =>
                            item.code?.toLowerCase() ===
                            row.country?.toLowerCase()
                        )?.code || ""
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className="min-w-[100px]" align="right">
                    {row.condition}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.discount ? `${row?.currencyCode} ` : ""}
                    {row.discount}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.price ? `${row?.currencyCode} ` : ""}
                    {row.price}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.createdAt?.toDate()?.toDateString()}
                    {/* {row?.createdAt?.toDate()?.toLocaleTimeString()} */}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.status === "APPROVED" ? (
                      <RejectButton
                        style={{ backgroundColor: "rgb(198, 40, 40)" }}
                        variant="contained"
                        onClick={() =>
                          onDelete(
                            row?.id,
                            row?.status === "APPROVED" ? true : false
                          )
                        }
                      >
                        Reject
                      </RejectButton>
                    ) : (
                      <ApproveButton
                        style={{ backgroundColor: "rgb(46, 125, 50)" }}
                        variant="contained"
                        onClick={() =>
                          onDelete(
                            row?.id,
                            row?.status === "APPROVED" ? true : false
                          )
                        }
                      >
                        Approve
                      </ApproveButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                    <p>{row.id}</p>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.phone}</StyledTableCell>
                  <StyledTableCell align="right">
                    <div className="flex flex-row text-right justify-end">
                      {countries.find(
                        (item) =>
                          item.code?.toLowerCase() ===
                          row.country?.toLowerCase()
                      )?.label || ""}
                      &nbsp; &nbsp;
                      {getFlagEmoji(
                        countries.find(
                          (item) =>
                            item.code?.toLowerCase() ===
                            row.country?.toLowerCase()
                        )?.code || ""
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(val) =>
                        onPerformActionUser(val.target.value, row)
                      }
                    >
                      <FormControlLabel
                        value="unblock"
                        checked={!row?.isBlocked && !row?.isDeactivated}
                        control={<Radio />}
                        label="unblock"
                      />
                      <FormControlLabel
                        value="block"
                        checked={row?.isBlocked}
                        control={<Radio />}
                        label="Block"
                      />
                      <FormControlLabel
                        value="deactivate"
                        checked={row?.isDeactivated}
                        control={<Radio />}
                        label="Deactivate"
                      />
                    </RadioGroup>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <br />
      {isLoading && (
        <>
          <center>
            <CircularProgress color="primary" />
          </center>
          <br />
        </>
      )}
      <center>
        <Button onClick={onLoadMore} className="capitalize" color={"primary"}>
          Load more
        </Button>
      </center>
    </>
  );
};
