import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FileCopy from "@material-ui/icons/FileCopy";
import Drawer from "@material-ui/core/Drawer";
import { useWindowSize } from "../utils/useWindowSize";
import List from "@material-ui/core/List";
import ListItemButton from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import { widthOfDrawer } from "../utils/constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    background: {
      background: 'white',
      width: widthOfDrawer,
    },
    box: {
      flex: 1,
      backgroundColor: theme.palette.background.default,
      minHeight: window.innerHeight,
    },
    grow: {
      flexGrow: 1,
    },
    growShift: {
      flexGrow: 1,
      marginLeft: widthOfDrawer,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: 'white',
    },
    title: {
      color: 'white',
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
      color: theme.palette.text.primary,
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      color: theme.palette.text.primary,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      color: 'white',
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      color: 'white',
    },
    ListItemButton: {
      cursor: "pointer",
    },
    heading: {
      marginLeft: 10,
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
  };
});

export const DashboardContainer = (props) => {
  const classes = useStyles();
  const [leftMenu, setleftMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const { width } = useWindowSize();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const history = useHistory()

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    if (width < 600) {
      setleftMenu(false);
    }
  };

  const Logout = () => {
    window.localStorage.clear()
    window.location.reload()
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={Logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.box}>
      <Drawer
        variant={width < 600 ? "temporary" : "persistent"}
        open={leftMenu}
        onClose={() => setleftMenu(false)}
        anchor={"left"}
      >
        <div className={classes.background}>
          <Box
            sx={{
              width: "100%",
              maxWidth: widthOfDrawer,
              bgcolor: "background.paper",
            }}
          >
            <List component="nav" aria-label="main mailbox folders">
              <p className={classes.heading}>Menu</p>

              <ListItemButton
                className={classes.ListItemButton}
                onClick={(event) => { 
                  history.push('/')
                  window.location.reload()
                }}
              >
                <ListItemIcon>
                  <FileCopy />
                </ListItemIcon>
                <ListItemText primary={`Users`} />
              </ListItemButton>
              <ListItemButton
                className={classes.ListItemButton}
                onClick={(event) => { 
                  history.push('/posts')
                  window.location.reload()
                }}
              >
                <ListItemIcon>
                  <FileCopy />
                </ListItemIcon>
                <ListItemText primary={`Posts`} />
              </ListItemButton>
            </List>
            {/* <Divider />
            <List component="nav" aria-label="secondary mailbox folder">
              <ListItemButton
                className={classes.ListItemButton}
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemText primary="Settings" />
              </ListItemButton>
              <ListItemButton
                className={classes.ListItemButton}
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemText primary="Billings" />
              </ListItemButton>
            </List> */}
          </Box>
        </div>
      </Drawer>
      <div
        className={`${width > 600 && leftMenu && classes.growShift} ${
          classes.grow
        } `}
      >
        <div style={{ flex: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => setleftMenu(!leftMenu)}
              >
                {leftMenu ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                Admin
              </Typography>

              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </div>

        {props.children}
      </div>
    </div>
  );
};
